import React, { MouseEvent, useCallback, useMemo } from 'react';

import Icon from '../icon';
import Spinner from '../spinner';
import Image from '../image';

import { FileInputValue } from '@common/types/objects';

type ImagePreviewOwnProps = {
  file: FileInputValue;
  size?: number;
  rounded?: boolean;
  onRemove?: () => void;
};

const ImagePreview = ({ file, size = 100, rounded, onRemove }: ImagePreviewOwnProps) => {
  if (!file || (!file.preview && !file.path)) return null;

  const handleRemove = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (onRemove) onRemove();
  }, [onRemove]);

  const previewElement = useMemo(() => {
    if (!file) return null;

    const [type] = (file.file_type || file.type || '').split('/');
    switch (type) {
      case 'image':
      case 'gif':
        if (!file.preview && !file.path) return <Icon type="image" />;
        return (
          <Image
            rounded={rounded}
            size={size}
            className="FilePreview__Image"
            src={file.preview || file.path}
            role="presentation"
          />
        );
      case 'video':
        return (
          <div className="FilePreview__Attachment">
            <Icon type="videocam" />
          </div>
        );
      default:
        return (
          <div className="FilePreview__Attachment">
            <Icon type="attach_file" />
          </div>
        );
    }
  }, [file, rounded, size]);

  const processing = !!file.processing;
  const styles = { width: size, height: size, borderRadius: rounded ? '50%' : 0 };
  return (
    <div className="FilePreview FilePreview--image" style={styles}>
      <div className={`FilePreview__overlay${processing ? ' FilePreview__overlay--show' : ''}`}>
        <div onClick={handleRemove} className="FilePreview__overlay__inner" style={styles}>
          {processing ? <Spinner centered /> : <Icon type="close" />}
        </div>
      </div>

      <div
        className={`FilePreview__Container${processing ? ' FilePreview--processing' : ''}`}
        style={{ ...styles, marginTop: -size }}
      >
        {previewElement}
      </div>
    </div>
  );
};

export default ImagePreview;
